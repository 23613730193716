export const getCurrencySymbol = currency => {
  const symbols = {
    EUR: "€",
    USD: "$",
  }

  return symbols[currency] || currency
}

export const IS_ITALY = process.env.GATSBY_COUNTRY === "IT"

export const CURRENCY_SYMBOL = getCurrencySymbol(process.env.GATSBY_CURRENCY)
