import React, { useMemo } from "react"
import PropTypes from "prop-types"
import "./CourseTile.less"
import AtsCard from "./AtsCard"
import { AuthorList, Duration, Level, VideoCount } from "./Labels"
import {
  formatDate,
  getCourseAuthorList,
  getCourseTotalTime,
  getImageUrl,
  translatePath,
} from "../utils"
import { FavoriteButton, MoreInfoButton, PlayButton } from "./Buttons"
import { navigate } from "gatsby"
import useFavorite from "../hooks/useFavorite"
import { COURSE } from "../enums/ItemTypes"
import { Tag } from "antd"
import { ON_DEMAND } from "../enums/PricePolicies"
import sportScienceLogo from "../../static/assets/images/ico-card.svg"
import { useTranslation } from "react-i18next"
import {
  DEFAULT_LANDSCAPE_IMAGE_SIZE,
  DEFAULT_PORTRAIT_IMAGE_SIZE,
} from "../constants/imageSizes"
import useBreakpoint from "../hooks/useBreakpoint"
import moment from "moment"
import { FREE } from "../enums/Plans"
import { CURRENCY_SYMBOL } from "../constants/settings"

const CourseTile = ({
  data,
  onClick,
  type = "compact",
  onPlay,
  onShowInfo,
  onToggleFavorite,
  width,
  badge,
  className,
  rank,
  isVertical,
  ...otherProps
}) => {
  const {
    id,
    title,
    subtitle,
    short_description,
    videos,
    landscape,
    portrait,
    price_policy,
    level,
    external_url,
    is_external,
    price,
    special_price,
  } = data

  const breakpoint = useBreakpoint()
  const isMobile = breakpoint === "xs" || breakpoint === "sm"
  const [isFavorite, setIsFavorite] = useFavorite(id, COURSE)

  const totalTime = getCourseTotalTime(data)
  const totalVideo = videos?.length ?? 0
  const authorList = getCourseAuthorList(data)

  // const canPlay = videos && videos[0]?.video?.state === PUBLISHED
  const canPlay = false

  const handleClick = () => {
    onClick && onClick(data)
  }

  const _onToggleFavorite = () => {
    setIsFavorite(!isFavorite)
  }

  let imageSize = DEFAULT_LANDSCAPE_IMAGE_SIZE
  let imageData = landscape

  if (isVertical && portrait) {
    imageSize = DEFAULT_PORTRAIT_IMAGE_SIZE
    imageData = portrait
  }

  const coverUrl = getImageUrl(imageData, imageSize)

  const { t } = useTranslation()
  const now = moment()

  return useMemo(() => {
    const actions = canPlay
      ? [
          <PlayButton
            onClick={() => {
              onPlay(data)
            }}
          />,
          <MoreInfoButton
            onClick={() => {
              onShowInfo(data)
              if (is_external) {
                window.open(external_url, "_blank").focus()
              } else {
                navigate(`/${translatePath("course")}/${data.seo.slug}`)
              }
            }}
          />,
          <FavoriteButton
            isFavorite={isFavorite}
            onClick={() => {
              onToggleFavorite(data)
              _onToggleFavorite()
            }}
          />,
        ]
      : [
          <MoreInfoButton
            onClick={() => {
              onShowInfo(data)
              if (is_external) {
                window.open(external_url, "_blank").focus()
              } else {
                navigate(`/${translatePath("course")}/${data.seo.slug}`)
              }
            }}
          />,
          <FavoriteButton
            isFavorite={isFavorite}
            onClick={() => {
              onToggleFavorite(data)
              _onToggleFavorite()
            }}
          />,
        ]
    const extra = is_external
      ? [
          <Level level={level} />,
          <Tag className="course-tag" color="default">
            {price_policy === ON_DEMAND ? t("label:course") : t("label:series")}
          </Tag>,
        ]
      : [
          <Duration totalTime={totalTime} />,
          <VideoCount count={totalVideo} />,
          <AuthorList authorList={authorList} />,
          <Level level={level} />,
          <Tag className="course-tag" color="default">
            {price_policy === ON_DEMAND ? t("label:course") : t("label:series")}
          </Tag>,
        ]

    let promoInfo
    if (
      moment(special_price?.end_at) > now &&
      price &&
      special_price?.final_price
    ) {
      promoInfo = {
        offerEndDate: formatDate(special_price.end_at),
        originalPrice: price + CURRENCY_SYMBOL,
        salePrice: special_price.final_price + CURRENCY_SYMBOL,
      }
    } else if (price && price !== 0 && price_policy !== FREE) {
      promoInfo = {
        originalPrice: price + CURRENCY_SYMBOL,
      }
    }

    return (
      <div className={`course-tile ${className}`}>
        <AtsCard
          width={width}
          title={title}
          subtitle={subtitle}
          description={short_description}
          cover={coverUrl}
          badge={badge}
          rank={
            rank || (
              <div className="icon-wrapper">
                <img className="ico-logo-img" src={sportScienceLogo} alt="" />
              </div>
            )
          }
          actions={actions}
          extra={extra}
          type={isMobile && isVertical ? "compact" : type}
          onClick={handleClick}
          promo={promoInfo}
        />
      </div>
    )
  }, [isFavorite, data, canPlay, breakpoint])
}

CourseTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onPlay: PropTypes.func,
  onShowInfo: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  badge: PropTypes.node,
  type: PropTypes.oneOf(["full", "compact"]),
}

CourseTile.defaultProps = {
  type: "compact",
  onClick: () => {},
  onPlay: () => {},
  onShowInfo: () => {},
  onToggleFavorite: () => {},
}

CourseTile.displayName = "Course Tile"
CourseTile.whyDidYouRender = true

export default CourseTile
